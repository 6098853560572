export const WHITE = "#fff";
export const GREY = "#808080";
export const BLACK = "#1e293b";
export const MEDIUMGREY = "#9ca3af";
export const LIGHTGREY = "#e5e7eb";
export const YELLOW = "#fffe00";
export const GREEN = "#5dff79";
export const CYAN = "#56f3ff";
export const PINK = "#ffcaf7";
export const ORANGE = "#ffc66f";
export const TEAL = "#2dd4bf";
export const INDIGO = "#818cf8";
export const AMBER = "#fbbf24";
export const ROSE = "#fb7185";
export const SKY = "#38bdf8";
export const DARKORANGE = "#f7653e"
