export const verseCarryingMarkers = [
  "li",
  "li1",
  "li2",
  "li3",
  "lh",
  "lf",
  "lim",
  "litl",
  "lik",
  "liv",
  "liv1",
  "liv2",
  "liv3",
  "th",
  "th1",
  "th2",
  "th3",
  "thr",
  "thr1",
  "thr2",
  "thr3",
  "tc",
  "tc1",
  "tc2",
  "tc3",
  "tcr",
  "tcr1",
  "tcr2",
  "tcr3",
  "add",
  "bk",
  "dc",
  "k",
  "lit",
  "nd",
  "ord",
  "pn",
  "png",
  "addpn",
  "qt",
  "sig",
  "sls",
  "tl",
  "wj",
  "em",
  "bd",
  "it",
  "bdit",
  "no",
  "sc",
  "sup",
  "w",
  "rb",
  "wa",
  "wg",
  "wh",
  "pro",
  "+add",
  "+bk",
  "+dc",
  "+k",
  "+lit",
  "+nd",
  "+ord",
  "+pn",
  "+png",
  "+addpn",
  "+qt",
  "+sig",
  "+sls",
  "+tl",
  "+wj",
  "+em",
  "+bd",
  "+it",
  "+bdit",
  "+no",
  "+sc",
  "+sup",
  "+w",
  "+rb",
  "+wa",
  "+wg",
  "+wh",
  "+pro",
];
export const punctPattern1 = new RegExp("^[,.\\-—/;:!?@$%^)}\\]>”»]"); // no space before
export const punctPattern2 = new RegExp("[\\-—/`@^&({[<“«]$"); // no space after
