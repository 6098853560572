import { SKY, TEAL, ROSE, INDIGO, AMBER } from "./colorCode";

export const bibleChapters = {
  gen: 50,
  exo: 40,
  lev: 27,
  num: 36,
  deu: 34,
  jos: 24,
  jdg: 21,
  rut: 4,
  "1sa": 31,
  "2sa": 24,
  "1ki": 22,
  "2ki": 25,
  "1ch": 29,
  "2ch": 36,
  ezr: 10,
  neh: 13,
  est: 10,
  job: 42,
  psa: 150,
  pro: 31,
  ecc: 12,
  sng: 8,
  isa: 66,
  jer: 52,
  lam: 5,
  ezk: 48,
  dan: 12,
  hos: 14,
  jol: 3,
  amo: 9,
  oba: 1,
  jon: 4,
  mic: 7,
  nam: 3,
  hab: 3,
  zep: 3,
  hag: 2,
  zec: 14,
  mal: 4,
  mat: 28,
  mrk: 16,
  luk: 24,
  jhn: 21,
  act: 28,
  rom: 16,
  "1co": 16,
  "2co": 13,
  gal: 6,
  eph: 6,
  php: 4,
  col: 4,
  "1th": 5,
  "2th": 3,
  "1ti": 6,
  "2ti": 4,
  tit: 3,
  phm: 1,
  heb: 13,
  jas: 5,
  "1pe": 5,
  "2pe": 3,
  "1jn": 5,
  "2jn": 1,
  "3jn": 1,
  jud: 1,
  rev: 22,
};
export const bibleBooks = [
  {
    book: "Genesis",
    bookId: 1,
    abbreviation: "gen",
  },
  {
    book: "Exodus",
    bookId: 2,
    abbreviation: "exo",
  },
  {
    book: "Leviticus",
    bookId: 3,
    abbreviation: "lev",
  },
  {
    book: "Numbers",
    bookId: 4,
    abbreviation: "num",
  },
  {
    book: "Deuteronomy",
    bookId: 5,
    abbreviation: "deu",
  },
  {
    book: "Joshua",
    bookId: 6,
    abbreviation: "jos",
  },
  {
    book: "Judges",
    bookId: 7,
    abbreviation: "jdg",
  },
  {
    book: "Ruth",
    bookId: 8,
    abbreviation: "rut",
  },
  {
    book: "1 Samuel",
    bookId: 9,
    abbreviation: "1sa",
  },
  {
    book: "2 Samuel",
    bookId: 10,
    abbreviation: "2sa",
  },
  {
    book: "1 Kings",
    bookId: 11,
    abbreviation: "1ki",
  },
  {
    book: "2 Kings",
    bookId: 12,
    abbreviation: "2ki",
  },
  {
    book: "1 Chronicles",
    bookId: 13,
    abbreviation: "1ch",
  },
  {
    book: "2 Chronicles",
    bookId: 14,
    abbreviation: "2ch",
  },
  {
    book: "Ezra",
    bookId: 15,
    abbreviation: "ezr",
  },
  {
    book: "Nehemiah",
    bookId: 16,
    abbreviation: "neh",
  },
  {
    book: "Esther",
    bookId: 17,
    abbreviation: "est",
  },
  {
    book: "Job",
    bookId: 18,
    abbreviation: "job",
  },
  {
    book: "Psalms",
    bookId: 19,
    abbreviation: "psa",
  },
  {
    book: "Proverbs",
    bookId: 20,
    abbreviation: "pro",
  },
  {
    book: "Ecclesiastes",
    bookId: 21,
    abbreviation: "ecc",
  },
  {
    book: "Song of solomon",
    bookId: 22,
    abbreviation: "sng",
  },
  {
    book: "Isaiah",
    bookId: 23,
    abbreviation: "isa",
  },
  {
    book: "Jeremiah",
    bookId: 24,
    abbreviation: "jer",
  },
  {
    book: "Lamentations",
    bookId: 25,
    abbreviation: "lam",
  },
  {
    book: "Ezekiel",
    bookId: 26,
    abbreviation: "ezk",
  },
  {
    book: "Daniel",
    bookId: 27,
    abbreviation: "dan",
  },
  {
    book: "Hosea",
    bookId: 28,
    abbreviation: "hos",
  },
  {
    book: "Joel",
    bookId: 29,
    abbreviation: "jol",
  },
  {
    book: "Amos",
    bookId: 30,
    abbreviation: "amo",
  },
  {
    book: "Obadiah",
    bookId: 31,
    abbreviation: "oba",
  },
  {
    book: "Jonah",
    bookId: 32,
    abbreviation: "jon",
  },
  {
    book: "Micah",
    bookId: 33,
    abbreviation: "mic",
  },
  {
    book: "Nahum",
    bookId: 34,
    abbreviation: "nam",
  },
  {
    book: "Habakkuk",
    bookId: 35,
    abbreviation: "hab",
  },
  {
    book: "Zephaniah",
    bookId: 36,
    abbreviation: "zep",
  },
  {
    book: "Haggai",
    bookId: 37,
    abbreviation: "hag",
  },
  {
    book: "Zechariah",
    bookId: 38,
    abbreviation: "zec",
  },
  {
    book: "Malachi",
    bookId: 39,
    abbreviation: "mal",
  },
  {
    book: "Matthew",
    bookId: 40,
    abbreviation: "mat",
  },
  {
    book: "Mark",
    bookId: 41,
    abbreviation: "mrk",
  },
  {
    book: "Luke",
    bookId: 42,
    abbreviation: "luk",
  },
  {
    book: "John",
    bookId: 43,
    abbreviation: "jhn",
  },
  {
    book: "Acts",
    bookId: 44,
    abbreviation: "act",
  },
  {
    book: "Romans",
    bookId: 45,
    abbreviation: "rom",
  },
  {
    book: "1 Corinthians",
    bookId: 46,
    abbreviation: "1co",
  },
  {
    book: "2 Corinthians",
    bookId: 47,
    abbreviation: "2co",
  },
  {
    book: "Galatians",
    bookId: 48,
    abbreviation: "gal",
  },
  {
    book: "Ephesians",
    bookId: 49,
    abbreviation: "eph",
  },
  {
    book: "Philippians",
    bookId: 50,
    abbreviation: "php",
  },
  {
    book: "Colossians",
    bookId: 51,
    abbreviation: "col",
  },
  {
    book: "1 Thessalonians",
    bookId: 52,
    abbreviation: "1th",
  },
  {
    book: "2 Thessalonians",
    bookId: 53,
    abbreviation: "2th",
  },
  {
    book: "1 Timothy",
    bookId: 54,
    abbreviation: "1ti",
  },
  {
    book: "2 Timothy",
    bookId: 55,
    abbreviation: "2ti",
  },
  {
    book: "Titus",
    bookId: 56,
    abbreviation: "tit",
  },
  {
    book: "Philemon",
    bookId: 57,
    abbreviation: "phm",
  },
  {
    book: "Hebrews",
    bookId: 58,
    abbreviation: "heb",
  },
  {
    book: "James",
    bookId: 59,
    abbreviation: "jas",
  },
  {
    book: "1 Peter",
    bookId: 60,
    abbreviation: "1pe",
  },
  {
    book: "2 Peter",
    bookId: 61,
    abbreviation: "2pe",
  },
  {
    book: "1 John",
    bookId: 62,
    abbreviation: "1jn",
  },
  {
    book: "2 John",
    bookId: 63,
    abbreviation: "2jn",
  },
  {
    book: "3 John",
    bookId: 64,
    abbreviation: "3jn",
  },
  {
    book: "Jude",
    bookId: 65,
    abbreviation: "jud",
  },
  {
    book: "Revelation",
    bookId: 66,
    abbreviation: "rev",
  },
];

export const colorGroup = {
  gen: TEAL,
  exo: TEAL,
  lev: TEAL,
  num: TEAL,
  deu: TEAL,
  jos: INDIGO,
  jdg: INDIGO,
  rut: INDIGO,
  "1sa": INDIGO,
  "2sa": INDIGO,
  "1ki": INDIGO,
  "2ki": INDIGO,
  "1ch": INDIGO,
  "2ch": INDIGO,
  ezr: INDIGO,
  neh: INDIGO,
  est: INDIGO,
  job: ROSE,
  psa: ROSE,
  pro: ROSE,
  ecc: ROSE,
  sng: ROSE,
  isa: ROSE,
  jer: ROSE,
  lam: ROSE,
  ezk: ROSE,
  dan: ROSE,
  hos: AMBER,
  jol: AMBER,
  amo: AMBER,
  oba: AMBER,
  jon: AMBER,
  mic: AMBER,
  nam: AMBER,
  hab: AMBER,
  zep: AMBER,
  hag: AMBER,
  zec: AMBER,
  mal: AMBER,
  mat: ROSE,
  mrk: ROSE,
  luk: ROSE,
  jhn: ROSE,
  act: TEAL,
  rom: INDIGO,
  "1co": INDIGO,
  "2co": INDIGO,
  gal: INDIGO,
  eph: INDIGO,
  php: INDIGO,
  col: INDIGO,
  "1th": INDIGO,
  "2th": INDIGO,
  "1ti": INDIGO,
  "2ti": INDIGO,
  tit: INDIGO,
  phm: INDIGO,
  heb: SKY,
  jas: SKY,
  "1pe": SKY,
  "2pe": SKY,
  "1jn": SKY,
  "2jn": SKY,
  "3jn": SKY,
  jud: SKY,
  rev: AMBER,
};
